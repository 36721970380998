import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import HeaderCase from 'blocks/case/headerCase'
import SectionHalfIntroduction from 'blocks/case/sectionHalfIntroduction'
import SectionHeadingFullWidth from 'blocks/case/sectionHeadingFullWidth'
import Dinamiza from 'components/colorDinamiza'
import SectionTextImageContainer from 'blocks/case/sectionTextImageContainer'
import ParagraphLine from 'components/paragraphLine'
import Image from 'components/image'
import style from 'scss/pages/projects/vega.module.scss'
import SectionDark from 'components/sectionDark'
import SectionTextImageFullWidth from 'blocks/case/sectionTextImageFullWidth'
import SectionSlider from 'blocks/case/sectionSlider'
import FooterCase from 'blocks/case/footerCase'
import VideoIllustration from "blocks/case/videoIllustration";
import redroutesAnimation from 'images/projects/enternow/redroutes.json'
import avanzateAnimation from 'images/projects/enternow/avanzate_enow.json'

class Enternow extends React.Component {

  render() {

    const { location, data } = this.props

    const imagePath = '/img/projects/enternow/'

    const headerInfo = {
      data: "22.08.2019",
      color: "#fff",
      logoUrl: imagePath+'logo_enternow.svg',
      image: imagePath+'copertina_enow.jpg',
      title: "Un ecommerce verticale sul settore dell'organizzazione delle manifestazioni sportive",
      tags: ["UI-UX DESIGN","BRAND IDENTITY","PROJECT MANAGEMENT","SOFTWARE ARCHITECTURE","SYSTEM INTEGRATION","WEB DEVELOPMENT"]
    }

    const slideImages = ['/img/screenshot/enow1.jpg','/img/screenshot/enow2.jpg','/img/screenshot/enow3.jpg']

    return(
      <div>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Enternow" description={'Affidaci il tuo progetto di business online, realizziamo siti internet, ecommerce, applicazioni per vendere online. Contattaci per una consulenza '} />

          <HeaderCase data={headerInfo} />

          <SectionHalfIntroduction>
            <h2>Il progetto che nasce per un servizio più veloce, sicuro ed efficiente</h2>
            <p>L’esigenza iniziale di Enternow, piattaforma di ticketing per corse podistiche, era quella di migliorare le prestazioni in termini di velocità ed efficienza, in modo da diminuire le risorse HW dedicate e migliorare l’usabilità sia lato azienda che lato cliente finale prestando particolare attenzione alla sicurezza delle transazioni e dei dati.</p>
          </SectionHalfIntroduction>

          <SectionTextImageFullWidth textPosition={'left'} padding={false} imageFade={false}>
            <div style={{marginRight:'auto'}}>
              <VideoIllustration src={redroutesAnimation} />
            </div>
            <ParagraphLine>
              <h2 data-title>Ridefinizione dell’esperienza utente</h2>
              <p>La sintesi di 3 piattaforme ha richiesto uno studio approfondito della UX, attraverso questionari, redroute, personas sia per la parte utente che per il pannello di amministrazione.</p>
            </ParagraphLine>
          </SectionTextImageFullWidth>

          <SectionTextImageContainer textPosition={'right'}>
            <div className={style.page__thirdContent}>
              <h2>Riprogettazione  dell'architettura del sistema e del database</h2>
              <p>Per raggiungere gli obiettivi di efficienza e facilità d’uso è stato necessario razionalizzare e riprogettare la struttura dei dati del database con l'obiettivo di mantenere per quanto possibile la migrabilità dei dati del precedente sistema verso la nuova struttura</p>
            </div>
            <div>
              <img src={imagePath+'architettura_enow.svg'} style={{ width:'100%' }} className={style.page__firstImage} />
            </div>
          </SectionTextImageContainer>

          <SectionDark imageSrc={imagePath+'bg_enow.jpg'} imagePosition={'top'}>

            <SectionTextImageFullWidth textPosition={'right'} padding={false} imageFade={false}>
              <VideoIllustration src={avanzateAnimation} />
              <ParagraphLine>
                <h2 data-title className={`has-color-primary-negative`}>Funzionalità avanzate lato admin</h2>
                <p className={`has-color-primary-negative`}>Lato admin sono state implementate funzionalità avanzate per permettere in modo autonomo di gestire iscrizioni e promozioni. Tra le più smart, un tool per consentire la configurazione del contact form di iscrizione in modo completamente flessibile per soddisfare tutte le esigenze che provengono dagli orgaizzatori di manifestazioni. Il sistema è una macchina a stati visuale collegata a tutte le funzionalità messe a disposizone della piattaforma.</p>
              </ParagraphLine>
            </SectionTextImageFullWidth>

            <SectionTextImageFullWidth textPosition={'left'} padding={false} imageFade={false}>
              <img src={imagePath+'mockup_enow.png'} className={style.page__firstImage} />
              <ParagraphLine>
                <h2 data-title className={`has-color-primary-negative`}>Test e Rilascio</h2>
                <p className={`has-color-primary-negative`}>La pubblicazione di una versione 2 di un sistema già esistente ma completamente diverso su una piattaforma mission critical con un alta molte di visite è estremamente delicata.<br/><br/>E' stato necessario organizzare diversi tipi di test e organizzare un team dedicato di sviluppo per far fronte ai picchi di assistenza che potevano derivare dall'apertura del sistema al pubblico nel primo periodo di rilascio.</p>
              </ParagraphLine>
            </SectionTextImageFullWidth>



            <SectionSlider slidesData={slideImages}>
              <h2 className={`has-color-primary-negative`}>Risultati</h2>
              <p className={`has-color-primary-negative`}>Rispetto al punto di partenza è stato ideato un sistema funzionale, totalmente flessibile e veloce in grado di gestire una mole ingente di richieste.</p>
            </SectionSlider>

          </SectionDark>

          <FooterCase prevProject={'pathlog'} nextProject={'aiti'} nextProjectColor={'#b10a33'} />

        </Layout>
      </div>
    )

  }
}

export default Enternow
